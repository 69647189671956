import dayjs from 'dayjs';
export const footerMixin = {
  data: () => ({
    language: 'en',
    lans: {
      en: 'English'
      // zh: "简体中文",
    },
    privacyItems: [
      {
        path: '/company-policy/privacy-policy',
        text: 'legal.title_1'
      },
      {
        path: '/company-policy/acceptable-use-policy',
        text: 'legal.title_2'
      },
      {
        path: '/company-policy/data-protection-policy',
        text: 'legal.title_3'
      },
      {
        path: '/company-policy/prohibited-goods-services',
        text: 'legal.title_4'
      }
    ],
    companyItems: [
      {
        path: '/about-us',
        title: 'about_us'
      },
      {
        path: '/partnership',
        title: 'partnership.title'
      },
      {
        path: '/contact-us',
        title: 'contact_us'
      },
      {
        path: '/newsroom',
        title: 'news_room'
      }
    ],
    documentsItems: [
      {
        title: 'developer_center',
        href: 'https://developer.evonetonline.com/',
        icon: '$vuetify.icons.mdiLoginVariant'
      },
      {
        path: '/company-policy',
        title: 'company_policy'
      },
      {
        path: '/mtc',
        title: 'merchant_terms_and_conditions'
      }
    ],
    isPageContactUs: false
  }),
  computed: {
    currentLanguage() {
      return this.lans[this.language];
    },
    year() {
      return dayjs().get('year');
    }
  },
  watch: {
    '$route.name': {
      handler: 'handlePageContactUs',
      immediate: true
    }
  },
  methods: {
    handlePageContactUs(name) {
      this.isPageContactUs = name === 'ContactUs';
    }
  }
};
